/* Works on Firefox */
/*
* {
  scrollbar-width: thin;
  scrollbar-color: blue #fff;
}
*/
/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: #fff;
}

*::-webkit-scrollbar-thumb {
  background-color: #3e81e7;
  border-radius: 0px;
}