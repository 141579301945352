.slot-times-date{
    width: 100%;
    font-size: 20px;
    color: #FFF;
    height: 25px;
    padding: 5px 0px;
}


@media (max-width: 600px){
    .slot-times-date{
        position: sticky;
        left: 4em;
        width: max-content;
    }
}