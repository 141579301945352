.grid-booking-content__header{
    display: grid;
    grid-template-columns: 4em 3em 5em 9em 9em 20em 15em 10em 10em minmax(12em, 2fr);
}
.grid-booking-content__bookings-row-wrapper{
    display: grid;
    grid-template-columns: 4em 1fr;
}
.grid-booking-content__bookings-row{
    display: grid;
    grid-template-columns: 3em 5em 9em 9em 20em 15em 10em 10em minmax(12em, 2fr);
}
.grid-booking-content__header.staff{
    grid-template-columns: 4em 2em 4em 20em minmax(10em, 7fr);
}
.grid-booking-content__bookings-row.staff{
    grid-template-columns: 2.5em 4em 20em minmax(10em, 7fr);
    justify-items: start;
}

@media (max-width: 600px){
    .grid-booking-content__bookings-row.staff{
        grid-template-columns: 2em 4em 16em minmax(10em, 7fr);
        justify-items: start;
    }
}
