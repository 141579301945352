.booking-header{
    display: flex;
    justify-content: space-between;
    height: 40px;
    width: 100%;
}
@media (max-width: 600px) {
    .booking-header{
        height: 2.5em;
        padding: 0 10px;
        width: calc(100% - 20px);
    }
}