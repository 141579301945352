.booking-panel{
    font-size: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: aliceblue;
    margin: 0 10px;
    border-radius: 10px;
    text-align: center;
    border-radius: 10px;
    overflow: auto;
}