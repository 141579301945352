.user-balances__operation{
    display: flex;
    flex-flow: wrap;
    padding: 5px;
}
.user-balances__operation-col-50{
    width: 50%;
}
.user-balances__operation-col-100{
    width: 100%;
}