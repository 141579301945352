.booking-panel__content{
    width: 100%;
    position: relative;
    flex: 1 0 auto;
}
.booking-panel__content-group{
    display: flex;
}
.booking-panel__content-header{
    margin: 0.5em 0 1em 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.booking-panel__content-title{
    margin: 0 5px;
    font-size: 18px;
}

.booking-panel__content-header-id{
    width: 7em;
}