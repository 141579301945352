.booking-content{
    height: calc(100dvh - 80px);
    display: flex;
    justify-content: space-between;
}
.booking-content__left{
    width: calc(100% - 300px - 0.5em);
    height: 100%;
    margin-left: 0.5em;
    padding: 0.5em 0.25em;
    box-sizing: border-box;
    background-color: aliceblue;
    border-radius: 10px;
}
.booking-content__right{
    width: 300px;
}
.booking-content__content{
    height: 100%;
    overflow: auto;
    /*min-width: 900px;*/
}

/* хром, сафари */
.booking-content__content::-webkit-scrollbar { width: 0; }

/* ie 10+ */
.booking-content__content { -ms-overflow-style: none; }

/* фф (свойство больше не работает, других способов тоже нет)*/
.booking-content__content { overflow: -moz-scrollbars-none; }


@media (max-width: 600px){
  .booking-content__left{
    width: 100%;
  }
  .booking-content{
    height: calc(100dvh - 120px);
  }
}