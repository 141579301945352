.bookings__header-wrapper{
  height: 30px;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 25;
}
.bookings__header{
  text-align: center;
  vertical-align: middle;
  word-wrap: break-word;
  text-transform: uppercase;
  font-weight: 550;
  color: rgb(62, 129, 231);
}
.bookings__header-item{
  padding: 5px 0 0 0;
  font-size: 0.875rem;
  background: #fff;
}

@media (max-width: 600px){
  .bookings__header-wrapper{
    display: none;
  }
}
