.bookings__search{
    height: 50px;
    padding: 5px 20px;
    z-index: 20;
    position: sticky;
    top: 30px;
    background-color: aliceblue;
    display:flex;
    flex-direction: row;
    }
    .bookings__date{
    position: sticky;
    z-index: 20;
    top: 80px;
    left: 0px;
    text-align: center;
    background: rgb(62, 129, 231);
    }
    .bookings__item{
    margin: 2px 0;
    }
    .bookings__item-datetime-wrapper{
    display: flex;
    font-size: 0.8em;
    text-align: center;
    background: rgb(138, 182, 247);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    }
    
    .bookings__item-datetime{
    margin: auto;
    }
    .bookings__item-time{
    font-weight: 700;
    }
    .bookings__item-date{
    font-size: 0.8em;
    }
    .bookings__search_item--field{
    margin-left: 1rem;
    }

    .bookings__search_item--icon{
        margin-top: 1rem;
        align-items: center;
        display: flex;
        color: rgb(62, 129, 231);
        cursor: pointer;
    }