.sticky-booking-content__header-col-1{
    position: sticky;
    left: 0px;
}
.sticky-booking-content__header-col-2{

}
.sticky-booking-content__bookings-row-col-1{
    position: sticky;
    left: 0px;
    z-index: 1;
}
.sticky-booking-content__bookings-row-col-2{
    justify-self: center;
}