.booking__time-slots{
    min-width: fit-content;
    width: 100%;
    margin-top: 3em;
}
.booking__time-slots-title{
    position: sticky;
    top: 30px;
    left: 0px;
    z-index: 20;
    text-align: center;
    background: rgb(62, 129, 231);
}
.booking__time-slots-title-weekend {
    background-color: crimson;
}
.booking__time-slots-loader {
    width: 100%;
    height: 4.8px;
    display: inline-block;
    position: relative;
    overflow: hidden;
}
.booking__time-slots-loader::after {
    content: '';
    width: 192px;
    height: 4.8px;
    background: #3498db;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    animation: animation-booking__time-slots-loader 2s linear infinite;
}

@keyframes animation-booking__time-slots-loader {
    0% {
        left: 0;
        transform: translateX(-100%);
    }
    100% {
        left: 100%;
        transform: translateX(0%);
    }
}


@media (max-width: 600px){
  .booking__time-slots-title{
      top: 0;
  }
  .booking__time-slots{
      min-width: 40em;
      width: 100%;
      margin-top: 3em;
  }
}