.booking__footer{
}
.booking__footer-release{
    position: fixed;
    bottom: 10px;
    right: -30px;
    z-index: 1000;
    text-transform: uppercase;
    margin: 5px;
    font-weight: 600;
    background: #fff;
    color: #222;
    border: solid 1px #94949442;

    width: 100px;
    height: 25px;
    text-align: center;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 27px;
    -ms-transform:rotate(-45deg);
    -webkit-transform:rotate(-45deg);
    transform:rotate(-45deg);
}