
.bookings__row{
  cursor: pointer;
  padding: 10px 0;
  background-color: rgb(156, 192, 157);
  line-height: 1;
  border-bottom: 1px dotted aliceblue;
  color: initial;
}
.bookings__row.prev-time{
  font-style: italic;
  color: #5e5e5e;
  background: #cccbcb;
}
.bookings__row.first-timer{
  color: #ae3a0d;
}
.bookings__row.staff-time{
  color: #080080;
}
.bookings__row.active{
  background-color: #8ab6f7;
}
.bookings__row.active:hover{
  background-color: #8ab6f7!important;
}
.bookings__row-item{
  font-size: 1rem;
  text-align: center;
  vertical-align: middle;
  word-wrap: break-word;
  margin: 0 2px;
}
.bookings__row-item_flex{
  display: flex;
}

.staff .bookings-row__booking-id{
  font-size: smaller;
  font-style: italic;
  padding-top: 0.1em;
  padding-left: 0.5em;
}
.bookings__row-item[tooltip]{
  position:relative;
}
.bookings__row-item[tooltip]::after {
  content: attr(tooltip);
  position: absolute;
  left:50%;
  top:-6px;
  padding:4px 2px;
  min-width: 10em;
  text-align: center;
  white-space: pre-wrap;
  font-size: 12px;
  border-radius: 5px;
  color: #fff;
  background: rgba(0,0,0,0.7);
  transform: translateX(-50%)   translateY(-100%);
  pointer-events: none;
  visibility: hidden;
}

.bookings__row-item[tooltip]:hover::after {
  visibility: visible;
  z-index: 21;
}