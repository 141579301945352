.booking-panel__loading{
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: 100;
    font-weight: 700;
}
.booking-panel__loading-success{
    margin: auto;
    color: green;
    padding: 20px;
}
.booking-panel__loading-error{
    margin: auto;
    color: red;
    padding: 20px;
}
.booking-panel__loading-loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: animation-booking-panel__loading-loader 2s linear infinite;
    margin: auto;
}

@keyframes animation-booking-panel__loading-loader {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}