.modal-yes-no__footer{
    display: flex;
}
.modal-yes-no__footer-left, .modal-yes-no__footer-right{
    width: 50%;
}
.modal-yes-no__footer-left{
    text-align: left;
}
.modal-yes-no__footer-right{
    text-align: right;
}