.booking-panel__header{
    position: sticky;
    top: 0px;
    width: 100%;
    z-index: 2;
    
}
.booking-panel__header-title{
    color: #FFF;
    background-color: #3e81e7;
    margin: 0;
    padding: 5px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.booking-panel__header-close{
    position: absolute;
    top: 4px;
    right: 5px;
    border: 2px solid #FFF;
    border-radius: 10px;
    color: white;
}