.input-number-plus-minus{
    /* font-size: 15px; */
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
}
.input-number-plus-minus__label{
    padding-left: 15px;
    text-align: left;
    margin: 0;
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.6);
}
.input-number-plus-minus__wrapper {
    display: flex;
    padding-left: 10px;
    align-items: center;
}
.input-number-plus-minus__input{
    width: 3em;
    /* font-size: 15px; */
}
.input-number-plus-minus-input__plus,
.input-number-plus-minus-input__minus{
    width: 100%;
    bottom: 0px;
    background: #7f91cf;
    color: #FFF;
    border-color: transparent;
    position: relative;
    cursor: pointer;
    font-size: 1em;
}
.input-number-plus-minus-input__plus{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.input-number-plus-minus-input__minus{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    top: -4px;
}
.input-number-plus-minus-input__value{
    width: calc(100% - 2px);
    height: 3em;
    padding: 0px;
    margin: -3px 0;
    color: #7f91cf;
    text-align: center;
    font-weight: 600;
    border: 1px solid #7f91cf;
    font-size: 1em;
}
.input-number-plus-minus__list{
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(3, 1fr);
    gap: 0.25em;
    padding: 0 7px;
    justify-items: center;
}
.input-number-plus-minus__list-item{
    font-size: 0.75em;
    width: 1.75em;
    height: 2em;
    margin: 0.1em;
    border: none;
    border-radius: 0.5em;
    cursor: pointer;
    text-align: center;
    background-color: #7f91cf61;
}
.input-number-plus-minus__list-item:hover{
    color: blue;
}