.booking__time-slot{
    display: flex;
    position: relative;
    min-height: 2em;
    flex-wrap: wrap;
    margin: 10px 3px;
    border: solid 1px #94949442;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background: #9cc09d;
}
.booking__time-slot:has(.booking__time-slot-btn){
    background: rgb(166 235 183);
}
.booking__time-slot.prev-time{
    /* filter: grayscale(80%) opacity(85%); */
    color: #5e5e5e;
    background: #b4b2b2;
}
.booking__time-slot.active{
    outline: 3px solid blue;
}
.booking__time-slot-time-wrapper{
    display: flex;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background: inherit;
}
.booking__time-slot-time{
    text-align: center;
    font-weight: 550;
    margin: auto;
}
.booking__time-slot-group{
    background-color: rgb(215, 255, 214);
}
.booking__time-slot.prev-time .booking__time-slot-group{
    background: #cccbcb;
}
/* .booking__time-slot-items{
} */
.booking__time-slot-btn{
    padding: 5px;
    vertical-align: middle;
}
.booking__time-slot.load::after{
    content: '';
    width: 192px;
    height: 4.8px;
    background: #3498db;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    animation: animation-booking__time-slot-loader 2s linear infinite;
}
.booking__time-slot:has(.first-timer){
    color: #ae3a0d;
}

@keyframes animation-booking__time-slot-loader {
    0% {
        left: 0;
        transform: translateX(-100%);
    }
    100% {
        left: calc(100% - 192px);
        transform: translateX(0%);
    }
}


