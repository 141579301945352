.booking-header__right{
    position: absolute;
    right: 0px;
}

@media (max-width: 400px) {
    .booking-header__right{
       position: relative;
       /* min-width: 100%; */
    }
    #FindBookingForm {
        display: none;
    }
    #modalUserBtn {
        display: none;
    }
}